import React, { useContext } from "react";
import { Interview4Content } from "../../../../organisms/interviews/Interview4Content";
import { RecruitNewbiewsNoLayout } from "../../../../organisms/careers/RecruitNewbiewsLayout";
import { SuprieveNowHeader } from "../../../../organisms/menus/SuprieveNowHeader";
import { Layout } from "../../../../organisms/Layout";
import { WindowSizeContext } from "../../../../atoms/core/WindowSizeProvidor";
import { md } from "../../../../atoms/core/media-queries";

const Content = () => {
  const { width: windowWidth } = useContext(WindowSizeContext);
  const extraHeader =
    windowWidth > md ? (
      <SuprieveNowHeader current="/4" prefix="/careers/new/suprieve-now" />
    ) : null;
  return (
    <RecruitNewbiewsNoLayout
      current="suprieve-now"
      extraHeader={extraHeader}
      isCareerInterview={true}
    >
      <Interview4Content prefix="/careers/new/suprieve-now" />
    </RecruitNewbiewsNoLayout>
  );
};

export default () => (
  <Layout>
    <Content />
  </Layout>
);
